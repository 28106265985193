import { Component, OnInit } from '@angular/core';
import {Injectable} from "@angular/core";
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { Router } from '@angular/router';

@Component({
  selector: 'app-pierauth',
  templateUrl: './pierauth.component.html',
  styleUrls: ['./pierauth.component.scss']
})
export class PierauthComponent implements OnInit {

  user: SocialUser;
  loggedIn: boolean;

  constructor(private router: Router, private authService: SocialAuthService) { }

  ngOnInit(): void {

    if (localStorage.getItem("authToken")){
      console.log("previously loggedin");
      this.user = new SocialUser();
      this.user.authToken = localStorage.getItem("authToken");
      this.user.id = localStorage.getItem("id");
      this.user.email = localStorage.getItem("email");
      this.user.firstName = localStorage.getItem("firstName");
      this.user.lastName = localStorage.getItem("lastName");
      this.user.name = localStorage.getItem("name");
      this.user.photoUrl = localStorage.getItem("photoUrl");
      //this.router.navigateByUrl('/');
    }
    else {
      this.router.navigateByUrl('/loginscreen');
    }
  }

  setUser (newUser: SocialUser) {
    if (newUser != null) {
      this.user = newUser;
      this.loggedIn = true;
      localStorage.setItem("authToken", this.user.authToken);
      localStorage.setItem("id", this.user.id);
      localStorage.setItem("email", this.user.email);
      localStorage.setItem("firstName", this.user.firstName);
      localStorage.setItem("lastName", this.user.lastName);
      localStorage.setItem("name", this.user.name);
      localStorage.setItem("photoUrl", this.user.photoUrl);
      this.router.navigateByUrl('/locations');
    }
  }

  async signOut () {

    try{
      localStorage.removeItem("authToken");
      localStorage.removeItem("id");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("name");
      localStorage.removeItem("photoUrl");
      await this.authService.signOut();
      this.router.navigateByUrl('/loginscreen');
    }
    catch(error){
      this.router.navigateByUrl('/loginscreen');
    }
    
  }

}

import { Component, OnInit } from '@angular/core';
import { PierauthComponent } from '../pierauth.component';
import { ActivatedRoute } from '@angular/router';

import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";



@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss']
})
export class LoginScreenComponent implements OnInit {


  user: SocialUser;
  loggedIn: boolean;

   

  constructor(private route: ActivatedRoute,
              private authService: SocialAuthService,
              private pierAuth: PierauthComponent
              ) {

     this.loggedIn = false;

  }

  ngOnInit() {

    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
      this.pierAuth.setUser(user);
    });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
}

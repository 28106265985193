<div class="my-container" id ="linear-gradient">
    <br>
    <br>
    <br>
    <br>
    <br>

    <div class="card card-custom gutter-b login-card mat-elevation-z8">
        
        <div class="card-body">
            <div class="card-title">
                <div><img src="../../../assets/media/logos/pierconnect_chicken_logo.png" class="login-logo-image"></div>
            </div>
            <div class="card-title">
                <div><img src="../../../assets/media/logos/logo-dark.png" class="login-logo"></div>
              </div>
            <div class="card-toolbar">
                <button type="button" class="login-button btn btn-primary btn-hover-danger" (click)="signInWithGoogle()">LOGIN</button>
            </div>
        </div>
    </div>
    
    
</div>

 